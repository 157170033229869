import React, { FC, useEffect, useState } from "react";
import { CompanyDescValueType, SenderData, SenderSuggestionKeyType, SenderSuggestionType } from "../../type/UserDataEvent";
import { LoaderResponse } from "../../components/Loader";
import PageGenerationProps from "../../interface/PageInterface";
import { SENDER_SAVING_STATE, SENDER_KEY, DOC_ID_KEY, DEST_SAVING_STATE, V2_SENDER_KEY, RECIPIENT_KEY } from "../../constants/StoreKey";
import { updateRecipientOrSenderData } from "../../services/UserServices";

import "../../styles/AureliaPage.css"
import { Redirect2NewPage } from "../../Helpers/Redirect2";
import { COLORS } from "../../constants/ColorsData";
import { encryptData } from "../../utils/util_function";
import ComboBox from "../../components/ComboBox";

const Sender:FC<PageGenerationProps> = ({userid, setPageNameToLoad, creditValue}) => {
    const user_id = userid
    const [requestStatus, setRequestStatus] = useState<boolean>(false);
    const [mailValidation, setMailValidation] = useState<boolean>(true);
    const [savingOk, setSavingOk] = useState<boolean>(false);
    const [loaderState, setLoaderState] = useState<boolean>(true);
    const [targetJobCount, setTargetJobCount] = useState<number>(0);
    const [targetJobList, setTargetJobList] = useState<Array<string>>([])
    const [targetJobValue, setTargetJobValue] = useState("");
    const [senderValueByTargetJob, setSenderValueByTargetJob] = useState("");
    const [selectedSuggestionKey, setSelectedSuggestionKey] = useState<SenderSuggestionKeyType>("firstname");
    const [hidePlaceholder, setHidePlaceholder] = useState(false);
    const [emptyField, setEmptyField] = useState({
        state1: false, state2: false, state3: false, state4: false, state5: false, state6: false, state7: false, state8: false, state9: false
    });
    const [suggestionIsClicked, setSuggestionIsClicked] = useState({
        state1: true, state2: true, state3: true, state4: true, state5: true, state6: true, state7: true, state8: true, state9: true
    });

    const [userFormData, setUserFormData] = useState<SenderData>({
        firstname: "",
        lastname: "",
        email_adress: "",
        job: "",
        companyName: "",
        companyActivity: "",
        serviceDesc: [],
        ref_companyActivity: "",
        ref_company_name: ""
    });

    const [tempCompanyValues, setTempCompanyValues] = useState<Array<CompanyDescValueType>>([])

    const [inputValue, setInputValue] = useState<string>("");
    const [suggestions, setSuggestions] = useState<SenderSuggestionType>({
        firstname: [],
        lastname: [],
        destination_email: [],
        senderJob: [],
        senderCompanyName: [],
        senderCompanyActivity: [],
        senderCompanyValue: [],
        companyRefDomain: [],
        companyRefList: []
    });

    const CACHE_KEY = "textCache";

    // Charger les suggestions du cache au chargement du composant
    useEffect(() => {
        const cachedTexts = localStorage.getItem(CACHE_KEY);
        if (cachedTexts) {
            setSuggestions(JSON.parse(cachedTexts));
        }
    }, []);

    // Sauvegarder la nouvelle entrée dans le cache
    const updateCache = (sender: any) => {
        // let cachedTexts: string[] = suggestions;
        let cachedTexts: SenderSuggestionType = {
            firstname: [],
            lastname: [],
            destination_email: [],
            senderJob: [],
            senderCompanyName: [],
            senderCompanyActivity: [],
            senderCompanyValue: [],
            companyRefDomain: [],
            companyRefList: []
        }

        // handleSubmit(sender.firstname, "firstname")
        // handleSubmit(sender.lastname, "lastname")
        // handleSubmit(sender.destination_email, "destination_email")
        // handleSubmit([sender.senderCompanyActivity].join(", "), "senderCompanyActivity")
        // handleSubmit(sender.senderCompanyName, "senderCompanyName")
        // handleSubmit([sender.senderCompanyValue].join(", "), "senderCompanyValue")
        // handleSubmit(sender.senderCompanyRef.companyRefDomain, "companyRefDomain")
        // handleSubmit([sender.senderCompanyRef.companyRefList].join(", "), "companyRefList")
        // Ajouter la nouvelle entrée si elle n'existe pas déjà
        if (!cachedTexts["firstname"].includes(sender.firstname)) {
            cachedTexts["firstname"].push(sender.firstname);
        }
        if (!cachedTexts["lastname"].includes(sender.lastname)) {
            cachedTexts["lastname"].push(sender.lastname);
        }
        if (!cachedTexts["destination_email"].includes(sender.destination_email)) {
            cachedTexts["destination_email"].push(sender.destination_email);
        }
        if (!cachedTexts["senderCompanyActivity"].includes([sender.senderCompanyActivity].join(", "))) {
            cachedTexts["senderCompanyActivity"].push([sender.senderCompanyActivity].join(", "));
        }
        if (!cachedTexts["senderCompanyName"].includes(sender.senderCompanyName)) {
            cachedTexts["senderCompanyName"].push(sender.senderCompanyName);
        }
        if (!cachedTexts["senderCompanyValue"].includes([sender.senderCompanyValue].join(", "))) {
            cachedTexts["senderCompanyValue"].push([sender.senderCompanyValue].join(", "));
        }
        if (!cachedTexts["companyRefDomain"].includes(sender.senderCompanyRef.companyRefDomain)) {
            cachedTexts["companyRefDomain"].push(sender.senderCompanyRef.companyRefDomain);
        }
        if (!cachedTexts["companyRefList"].includes([sender.senderCompanyRef.companyRefList].join(", "))) {
            cachedTexts["companyRefList"].push([sender.senderCompanyRef.companyRefList].join(", "));
        }
        localStorage.setItem(CACHE_KEY, JSON.stringify(cachedTexts));
        // setSuggestions(cachedTexts[key]); // Mettre à jour les suggestions localement
    };

    // Gérer la soumission (simule la sauvegarde du texte)
    const handleSubmit = (fieldValue: any) => {
        if (fieldValue) {
            updateCache(fieldValue);
            setInputValue(""); // Réinitialiser le champ de texte après soumission
        }
    };

    // Gérer les modifications du champ de texte
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
    };

    // Filtrer les suggestions basées sur l'entrée actuelle
    const filteredSuggestions = suggestions['senderCompanyValue'].filter((text_list) =>
        // text.toLowerCase().includes(inputValue.toLowerCase())
        text_list.includes(inputValue.toLowerCase())
    );
    
    const goToNextPage = () => {
        // setPageNameToLoad("Mail")
        if(localStorage.getItem(DEST_SAVING_STATE) === null){
            Redirect2NewPage("Aurélia", "Dashboard/Destinataire")
        }else Redirect2NewPage("Aurélia", `Dashboard/Mail`)
    }

    const updateFirstName = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, firstname: val.target.value }));
    }

    const updateLastName = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, lastname: val.target.value }));
    }

    const updateProfession = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, job: val.target.value }));
    }

    const updateCompanyName = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        if(val !== undefined) setUserFormData(prevState => ({ ...prevState, companyName: val.target.value }));
    }
    const updateCompanyActivity = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        if(val !== undefined) setUserFormData(prevState => ({ ...prevState, companyActivity: val.target.value }));
    }

    // Single company value
    const updateServiceDescription = (val: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, 
            serviceDesc: [val.target.value]
        }));
    }

    const updateRefCompanyActivity = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, ref_companyActivity: val.target.value }));
    }
    
    const updateRefCompanyName = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, ref_company_name: val.target.value }));
    }

    const updateEmailAdress = (val: React.ChangeEvent<HTMLInputElement>) => {
        setSavingOk(false)
        setUserFormData(prevState => ({ ...prevState, email_adress: val.target.value }));
    }

    const updateFinalCompanyValues = (companyValue: string) => {
        setSenderValueByTargetJob(companyValue)

        let tempArray: Array<CompanyDescValueType> = tempCompanyValues
        setInputValue(companyValue)
        if(targetJobCount > 1){ // Un poste trouvé ie plusieurs postes choisies dans déstinataire
            tempCompanyValues.forEach((element, index) => {
                if(targetJobValue === element.target_job){
                    tempArray[index].desc_value = companyValue
                }
            });
        } else {
            // un seul poste choisi
            tempArray = [{
                target_job: targetJobValue,
                desc_value: companyValue
            }]
        }
        
        setTempCompanyValues(tempArray)
        setUserFormData(prevState => ({ ...prevState, 
            serviceDesc: convertCompanyValues2TextList(tempArray)
        }));
    }

    // eslint-disable-next-line
    const getCompanyValueByJob = (job: string) => {
        const found = tempCompanyValues.find((element) => element.target_job === job)
        
        return found ? found.desc_value : ""
    }

    const convertCompanyValues2TextList = (data: Array<CompanyDescValueType>) => {
        let newList: Array<string> = []

        data.forEach(item => {
            newList.push(item.desc_value)
        });

        return newList 
    }

    const  validateEmail = (email: string) => {
        // Expression régulière pour valider une adresse e-mail
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        // Vérifier si l'e-mail correspond au format regex
        const isValid = emailRegex.test(email);
    
        // Si l'e-mail est invalide, colorer le champ d'entrée en rouge
        if (!isValid) {
            setMailValidation(false)
        } else {
            // Sinon, réinitialiser la couleur du champ d'entrée
            setMailValidation(true)
        }
    
        // Retourner si l'e-mail est valide ou non
        return isValid;
    }
    

    const saveUserDataToLocal = () => {
        if (!userFormData.firstname){
            setEmptyField(
                prevState => ({...prevState, state1: true})
            )
            return
        }else setEmptyField(prevState => ({...prevState, state1: false}))

        if(!userFormData.lastname) {
            setEmptyField(
                prevState => ({...prevState, state2: true})
            )
            return;
        } else setEmptyField(prevState => ({...prevState, state2: false}))

        if (!userFormData.email_adress){
            setEmptyField(
                prevState => ({...prevState, state3: true})
            )
            return
        }else {
            if(!validateEmail(userFormData.email_adress)) {
                return
            }
            setEmptyField(prevState => ({...prevState, state3: false}))
        }

        if(!userFormData.job) {
            setEmptyField(
                prevState => ({...prevState, state4: true})
            )
            return;
        } else setEmptyField(prevState => ({...prevState, state4: false}))

        if (!userFormData.companyName){
            setEmptyField(
                prevState => ({...prevState, state5: true})
            )
            return
        }else setEmptyField(prevState => ({...prevState, state5: false}))

        if(!userFormData.companyActivity) {
            setEmptyField(
                prevState => ({...prevState, state6: true})
            )
            return;
        } else setEmptyField(prevState => ({...prevState, state6: false}))

        // Vérification du champ de propositions de valeur
        // const userFormatDataSenderServiceList = targetJobCount <= 1 ? userFormData.serviceDesc : convertCompanyValues2TextList(tempCompanyValues)
        const userFormatDataSenderServiceList = convertCompanyValues2TextList(tempCompanyValues)
        
        // Charger la liste de poste
        const ls_dest_data = localStorage.getItem(RECIPIENT_KEY)
        let jobCount = 0

        if(ls_dest_data){
            const dest_data_value = JSON.parse(ls_dest_data)
            if(dest_data_value){
                jobCount = dest_data_value['targetJob'].length
            }
        }
        if(userFormatDataSenderServiceList.length !== jobCount) {
            setEmptyField(
                prevState => ({...prevState, state7: true})
            )
            return;
        }
        setEmptyField(prevState => ({...prevState, state7: false}))
        
        // if(!userFormData.ref_companyActivity) {
        //     setEmptyField(
        //         prevState => ({...prevState, state8: true})
        //     )
        //     return;
        // } else setEmptyField(prevState => ({...prevState, state8: false}))

        // if(!userFormData.ref_company_name) {
        //     setEmptyField(
        //         prevState => ({...prevState, state9: true})
        //     )
        //     return;
        // } else setEmptyField(prevState => ({...prevState, state9: false}))

        // Lancement du sauvegarde
        if(user_id){
            setRequestStatus(true)
            // setUserFormData(prev => ({...prev, user_id: user_id}))
            const doc_id = localStorage.getItem(DOC_ID_KEY)
            const sender = {
                "firstname": userFormData.firstname,
                "lastname": userFormData.lastname,
                "destination_email": userFormData.email_adress,
                "senderJob": userFormData.job,
                "senderCompanyName": userFormData.companyName,
                "senderCompanyValue": userFormatDataSenderServiceList,
                "senderCompanyActivity": [userFormData.companyActivity],
                "senderCompanyRef": {
                    "companyRefDomain": userFormData.ref_companyActivity,
                    "companyRefList": [userFormData.ref_company_name]
                }
            }
            
            encryptData(V2_SENDER_KEY, sender)
            localStorage.setItem(SENDER_KEY, JSON.stringify(userFormData))

            if(localStorage.getItem(SENDER_KEY)) {
                updateRecipientOrSenderData({
                    "_id": doc_id,
                    "user_id": user_id,
                    "cle": "sender",
                    "body" : userFormData
                }).then((rep: any) => {
                    if(doc_id === null || doc_id === undefined) localStorage.setItem(DOC_ID_KEY, rep.body._id)

                    setRequestStatus(false)
                    setSavingOk(true)
                    localStorage.setItem(SENDER_SAVING_STATE, String(true))

                    // Sauver en cache les textes
                    handleSubmit(sender)

                    goToNextPage()
                }).catch((e) => {
                    setRequestStatus(false)
                    console.error(e)
                })
            }
            
            // handleSubmit(sender.firstname, "firstname")
            // handleSubmit(sender.lastname, "lastname")
            // handleSubmit(sender.destination_email, "destination_email")
            // handleSubmit([sender.senderCompanyActivity].join(", "), "senderCompanyActivity")
            // handleSubmit(sender.senderCompanyName, "senderCompanyName")
            // handleSubmit([sender.senderCompanyValue].join(", "), "senderCompanyValue")
            // handleSubmit(sender.senderCompanyRef.companyRefDomain, "companyRefDomain")
            // handleSubmit([sender.senderCompanyRef.companyRefList].join(", "), "companyRefList")
        }
    }

    useEffect(() => {
        // Destinataire
        let recipient_data_retrieve = localStorage.getItem(RECIPIENT_KEY)
        
        if(recipient_data_retrieve) {
            let recip_json = JSON.parse(recipient_data_retrieve)
            
            if(recip_json) {
                const targetJobVal = recip_json['targetJob']
                if(targetJobVal) {
                    if(targetJobVal.length <= 1 ) setTargetJobValue(targetJobVal[0])
                    else setTargetJobCount(targetJobVal.length)
                    setTargetJobList(targetJobVal)
                    setLoaderState(false)
                }else {
                    setLoaderState(false)
                }
            }else {
                setLoaderState(false)
            }
        } else {
            setLoaderState(false)
            Redirect2NewPage("Aurélia", "Dashboard/Destinataire")
        }
    }, [])

    useEffect(() => {
        let recipient_data_retrieve = localStorage.getItem(RECIPIENT_KEY)
        // Emetteur
        let sender_data_retrieve = localStorage.getItem(SENDER_KEY)
        
        if(sender_data_retrieve) {
            let myJson = JSON.parse(sender_data_retrieve)
            
            setUserFormData(myJson)
            setSavingOk(Boolean(localStorage.getItem(SENDER_SAVING_STATE)))

            if(recipient_data_retrieve) {
                let recip_json = JSON.parse(recipient_data_retrieve)
                
                let tempValues: Array<CompanyDescValueType> = []
                recip_json['targetJob'].forEach((jobItem: string, index: number) => {
                    tempValues.push({
                        target_job: jobItem,
                        desc_value: myJson ? myJson['serviceDesc'][index] : ""
                    })
                })

                if(recip_json['targetJob'].length === 1) {
                    setTargetJobValue(recip_json['targetJob'][0])
                    setSenderValueByTargetJob(myJson['serviceDesc'][0])
                }

                setTempCompanyValues(tempValues)
            }
        }
    }, [])

    useEffect(() => {
        if(tempCompanyValues.length > 1){
            const current_desc = tempCompanyValues.find(element => element.target_job === targetJobValue)
            setSenderValueByTargetJob(
                current_desc ? current_desc.desc_value : ""
            )
        }
    }, [targetJobValue, tempCompanyValues])
    
    return (
        <div>
            <div id="form-input" className="scroll">
                <div className="col1">
                    <div className="row">
                        <label>Votre prénom</label>
                        <input type="text" className={emptyField.state1 ? "error-field input_text" : "no-error input_text"} value={userFormData.firstname} placeholder="Prénom" onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateFirstName(val)} required={true} />
                    </div>
                    <div style={{width: 20}}></div>
                    <div className="row">
                        <label>Votre nom</label>
                        <input type="text" className={emptyField.state2 ? "error-field input_text" : "no-error input_text"} value={userFormData.lastname} placeholder="Nom" onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateLastName(val)} required={true}/>
                    </div>
                </div>
                <div className="row">
                    <label>Email</label>
                    <input type="email" className={emptyField.state3 ? "error-field input_text" : "no-error input_text"} value={userFormData.email_adress} onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateEmailAdress(val)} placeholder="Adresse e-mail pour l'envoi de rapport" required={true} style={{ background: mailValidation ? "" : "red"}}/>
                </div>
                <div className="row">
                    <label>Profession</label>
                    <input type="text" className={emptyField.state4 ? "error-field input_text" : "no-error input_text"} value={userFormData.job} onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateProfession(val)} placeholder="Votre profession" required={true}/>
                </div>
                <div className="row">
                    <label>Nom de l'entreprise</label>
                    <input type="text" className={emptyField.state5 ? "error-field input_text" : "no-error input_text"} value={userFormData.companyName} onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateCompanyName(val)} placeholder="Le nom de votre entreprise" required={true}/>
                </div>
                <div className="row">
                    <label>Description de l’activité de l’entreprise</label>
                    <input type="text" className={emptyField.state6 ? "error-field input_text" : "no-error input_text"} value={userFormData.companyActivity} onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateCompanyActivity(val)} placeholder="Le domaine d'activité de l'entreprise" required={true}/>
                </div>
                <div className="row" style={{border: emptyField.state7 ? "solid 1px red" : ""}}>
                    <label>Description de la proposition de valeur</label>
                    {
                        loaderState ? (
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <LoaderResponse colorValue={COLORS.jauneClaire} />
                            </div>
                        ) : (
                            targetJobCount <= 1 ? (
                                // <input 
                                //     type="text" 
                                //     className={emptyField.state7 ? "error-field input_text" : "no-error input_text"} 
                                //     // value={userFormData.serviceDesc[0] ? userFormData.serviceDesc[0] : ""} 
                                //     // onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateServiceDescription(val)} 
                                //     value={senderValueByTargetJob}
                                //     onChange={(val) => updateFinalCompanyValues(val.target.value)}
                                //     placeholder="Les propositions de valeur de l'entreprise" 
                                //     required={true}
                                // />
                                
                                <div>
                                    <textarea 
                                        className={"textarea-as-input"}
                                        // value={userFormData.serviceDesc[0] ? userFormData.serviceDesc[0] : ""} 
                                        // onChange={(val: React.ChangeEvent<HTMLTextAreaElement>) => updateServiceDescription(val)}
                                        value={senderValueByTargetJob}
                                        onFocus={() => {setHidePlaceholder(true)}}
                                        onBlur={() => {setHidePlaceholder(false)}}
                                        onChange={(val) => {
                                            updateFinalCompanyValues(val.target.value)
                                            setInputValue(val.target.value)
                                        }}
                                        placeholder={hidePlaceholder ? "" : `Veuillez saisir les propositions de valeur associées à  ${targetJobValue}`}
                                    ></textarea>

                                    {/* Afficher les suggestions si elles existent */}
                                    {
                                        senderValueByTargetJob.length > 0 && filteredSuggestions.length > 0 && !suggestionIsClicked.state7 && (
                                        // senderValueByTargetJob.length > 0 && !suggestionIsClicked.state7 && (
                                        <ul>
                                        {filteredSuggestions.map((suggestion, index) => (
                                            <li key={index} style={{cursor: "pointer"}} onClick={
                                                () => {
                                                    setSuggestionIsClicked(prevState => ({...prevState, state7: true}))
                                                    setSenderValueByTargetJob(suggestion)
                                                }
                                            }>
                                            {suggestion}
                                            </li>
                                        ))}
                                        </ul>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <ComboBox 
                                        data={""} 
                                        getOptionIndex={setTargetJobValue}
                                        OptionData={[{
                                            "title": "Poste cible",
                                            "content" : targetJobList
                                        }]} />
                                    <div className="mail-prep-right-text">
                                        <textarea 
                                            disabled={targetJobValue ? false : true}
                                            className={"textarea-as-input"}
                                            value={senderValueByTargetJob}
                                            onChange={(val) => updateFinalCompanyValues(val.target.value)}
                                            placeholder={"Veuillez saisir les propositions de valeur associées à " + targetJobValue}></textarea>
                                    </div>
                                </div>
                            )
                        )
                    }
                    
                </div>

                <div className="row">
                    <label>Référencement (Optionnel)</label>
                    <div className="col1">
                        <div className="row">
                            <input type="text" className={emptyField.state8 ? "error-field input_text" : "no-error input_text"} value={userFormData.ref_companyActivity} onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateRefCompanyActivity(val)} placeholder="Domaine d'activité des entreprises" required={true}/>
                        </div>
                        <div style={{width: 20}}></div>
                        <div className="row">
                            <input type="text" className={emptyField.state9 ? "error-field input_text" : "no-error input_text"} value={userFormData.ref_company_name} onChange={(val: React.ChangeEvent<HTMLInputElement>) => updateRefCompanyName(val)} placeholder="Nom de chaque entreprise (Ex: Entreprise 1, Entreprise 2,...)" required={true}/>
                        </div>
                    </div>
                </div>
            </div>
            <div id="form-btn-container">
            {
                requestStatus ? (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <LoaderResponse colorValue={COLORS.jauneClaire} />
                        <p style={{color: 'black'}}>Enregistrement en cours...</p>
                    </div>
                ) : (
                    <button id="form-btn" onClick={saveUserDataToLocal}>{savingOk ? "C'est enregistré" : "Enregistrer"}</button>
                )
            }
        </div>
        </div>
    )
}

export default Sender
