export const MAIL_TITLE_EXAMPLE = "Solution d'IA pour tous"

export const MAIL_BODY_EXAMPLE = `
Bonjour John Doe,

J'espère que ce message vous trouve en pleine forme. Suite à votre dernière intervention au ...

Nous travaillons, chez [Entreprise], sur le développement d'une IA destinée à assister dans [domaine d'activité et autres informations].

Est-ce que vous connaissez une personne qui serait intéressé ? Pourrions-nous envisager une rencontre ou un appel pour explorer d'éventuelles synergies ?

Bien cordialement,`

export const USER_JOB_TITLE_SIGN = "AI Email Specialist"
export const CUSTOM_THEME = ["Post Linkedin", "Actualités", "Entreprise"]
export const CATEGORY = ["Target", "Partner"]
export const TYPE = ["persona_linkedin", "persona_news", "Company"]

export const FIRST_PAGE = "Destinataire" // la page à utiliser comme racine au cas où