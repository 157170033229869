import React, { FC } from "react";
import { Redirect2NewPage } from "../Helpers/Redirect2";
import SubNavMenuProps from "../interface/MenuInterface";

import "../styles/NavMenu.css"
import { USER_ID_KEY } from "../constants/StoreKey";

export const NavSubMenu:FC<SubNavMenuProps> = ({url, icon, title, activeMenu, menuIsClicked, desc_name, disable, menuTitle, jsonData}) => {

    const handleTab = (text: string) => {
        if(url){
            const id = localStorage.getItem(USER_ID_KEY)
            Redirect2NewPage(url, "", String(id))
        }else menuIsClicked({
            "page_name": text,
            "page_desc": desc_name
        })
    };

    return (
        <ul
            // className="aurelia-nav-menu"
            className={`aurelia-nav-menu ${disable ? "disable-hover" : "enable-hover"}`} 
            // style={disable ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
        >
            <li
                className={activeMenu === title ? "active" : "deactive"}
                onClick={() => !disable && handleTab(title)} // Éviter les clics si désactivé
                style={disable && activeMenu !== title ? {background: "transparent", border: "none"} : {}}
            >
                <img src={icon} alt="" width={20} height={20} />
                <p 
                    style={{
                        display: "flex", 
                        flex: 1, 
                        paddingLeft: "12px", 
                        fontWeight: 'normal', 
                        fontSize: 'small'
                    }}
                >
                    {menuTitle ? menuTitle : title}
                </p>
            </li>
        </ul>
    );
    
}