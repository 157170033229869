import React, { useEffect, useState } from 'react'
import HistoryCampaignTable, { ColumnNameType, UserCampaignDataType } from '../../components/Table/DataTable'
import { USER_ID_KEY } from '../../constants/StoreKey'
import { getUserDetailedCampaignHistory } from '../../services/UserServices'

import '../../styles/CampaignHistoryPage.css'
  
const column: Array<ColumnNameType> = [
    {
        colname: "id",
        labelname: "ID"
    },
    {
        colname: "user_id",
        labelname: "IDU"
    },
    {
        colname: "target_person_name",
        labelname: "Nom du cible"
    },
    {
        colname: "target_person_job",
        labelname: "Poste du cible"
    },
    {
        colname: "target_person_company_name",
        labelname: "Entreprise cible"
    },
    {
        colname: "mail_id",
        labelname: "IDM"
    },
    {
        colname: "mail_title",
        labelname: "Titre"
    },
    {
        colname: "mail_body",
        labelname: "Contenu"
    },
    {
        colname: "mail_status",
        labelname: "Etat du mail"
    },
    {
        colname: "mail_state_count",
        labelname: 'N° fois'
    },
    {
        colname: "sending_date",
        labelname: "Date d'envoi"
    }
]

const followUp_template = [
    `Bonjour {name},

    Vous voyez, même la relance est envoyée toute seule.

    Nos clients constatent une augmentation moyenne de 57% de leur taux de conversion dès le troisième mois (pour ceux ayant un cycle court). {company} peut bénéficier du même taux, voire plus, grâce à une IA qui automatise la prospection avant-vente et envoie des e-mails très personnalisés.

    Toujours pas intéressé pour échanger ? Ou peut-être préférez-vous discuter avec un humain pour découvrir notre méthode secrète ?

    Bien à vous,`,

    `Bonjour {name},

    J'espère que vous passez un excellent été !

    L'IA est une véritable révolution pour booster votre efficacité et optimiser votre emploi du temps. 

    Souhaitez-vous en parler à la rentrée ou discuter de nouveaux sujets passionnants dès maintenant ?

    En attendant votre retour, je vous souhaite d'excellentes vacances et vivez pleinement les Jeux Olympiques.

    Cordialement,`
]
const HistoryPage = () => {
    const [campagnHistoryData, setCampagnHistoryData] = useState<Array<UserCampaignDataType>>([])
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        const userid = localStorage.getItem(USER_ID_KEY)
        function convertToDate(dateStr: string) {
            const [day, month, yearAndTime] = dateStr.split("-");
            const [year, time] = yearAndTime.split(" ");
            return new Date(`${year}-${month}-${day}T${time}`);
          }
        if (userid) {
            getUserDetailedCampaignHistory(userid).then(
                (response: {
                    body: Array<UserCampaignDataType>
                }) => {
                
                if(response) {
                    const body = response.body
                    let body_with_index: Array<UserCampaignDataType> = []
                    body.forEach((item, index) => {
                        item['id'] = index

                        const dateObj = convertToDate(item['sending_date'])
                        // Obtenir le temps en millisecondes, puis convertir en secondes
                        const totalSeconds = Math.floor(dateObj.getTime() / 1000);
                        item['sending_date'] = totalSeconds.toString()
                        body_with_index.push(item)
                    })
                    setCampagnHistoryData(body_with_index)
                    if(campagnHistoryData.length > 0 ) setLoader(false)
                }
            }).catch((e) => {
                setLoader(false)
            })
        }
    }, [campagnHistoryData.length])

    useEffect(() => {
        if(campagnHistoryData.length > 0 ) setLoader(false)
    }, [loader, campagnHistoryData.length])

    return (
        <div className="campagn-history-main scroll">
            {
                loader ? (
                    <></>
                ) : (
                    <HistoryCampaignTable rows={campagnHistoryData} columnName={column} followUpTemplate={followUp_template} />
                )
            }
        </div>
    )
}

export default HistoryPage