import React, { FC } from "react";
import { Redirect2NewPage } from "../Helpers/Redirect2";
import NavMenuProps from "../interface/MenuInterface";

import "../styles/NavMenu.css"
import { USER_ID_KEY } from "../constants/StoreKey";

export const NavMenu:FC<NavMenuProps> = ({url, icon, title, activeMenu, menuIsClicked, desc_name, menuTitle, jsonData}) => {

    const handleTab = (text: string) => {
        if(url){
            const id = localStorage.getItem(USER_ID_KEY)
            Redirect2NewPage(url, "", String(id))
        }else menuIsClicked({
            "page_name": text,
            "page_desc": desc_name
        })
    };

    return (
        <ul className="aurelia-nav-menu">
            <li
                className={activeMenu === title ? "active" : "deactive"}
                onClick={() => handleTab(title)}
            >
                <img src={icon} alt="" width={25} height={25} />
                <p style={{display: "flex", flex: 1, paddingLeft: "20px", fontWeight: 'normal'}}>{menuTitle ? menuTitle : title}</p>
            </li>
        </ul>
    )
}