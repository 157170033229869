import React, { useCallback, useEffect, useRef, useState } from "react";

import "../../styles/MailPreparation.css"
import "../../styles/AureliaPage.css"
import '../../styles/MultiLineInput.css'
import "../../styles/SelectLanguage.css"

import Checkbox from "../../components/CheckBox";
import ComboBox from "../../components/ComboBox";
import { CheckBoxList, MailData2Send } from "../../interface/DataI";
import { ACCROCHE_KEY, CRM_API_KEY, DEST_SAVING_STATE, DOC_ID_KEY, DOMAINE_CHECKBOX_KEY, INCITATION_KEY, JOB_CHECKBOX_KEY, MAIL_ACC_CHECKBOX_KEY, MAIL_CHECKBOX_KEY, MAIL_LANGUAGE_KEY, MAIL_TITLE_CHECKBOX_KEY, PROMPT2LLM_KEY, RECIPIENT_KEY, SENDER_KEY, SENDER_SAVING_STATE, SF_CREDS_DATA, TECHNOLOGY_KEY, TITRE_MAIL_KEY, USER_INFO_KEY, V2_MAIL_KEY, V2_RECIPIENT_KEY, V2_SENDER_KEY } from "../../constants/StoreKey";
import { LoaderResponse } from "../../components/Loader";
// import { sendPromptToLLM } from "../../api/connect2llm";
import { Redirect2NewPage } from "../../Helpers/Redirect2";
import { FlagLanguageType, MailFormatCheckBoxType, RecipientData, SenderData } from "../../type/UserDataEvent";
import { INCITATION_DATA, LANGUAGE_LIST, MAIL_FORMAT_DATA, MAIL_TITLE_AND_STATE } from "../../constants/ArrayData";
import { getMailFormatListDataFromDB, updateUserMailForm4Aurelia } from "../../services/UserServices";
import PageGenerationProps from "../../interface/PageInterface";
import { convertFormatData2Text, decryptData, encryptData, getOnlyTrueStatus, ordonnerTableau } from "../../utils/util_function";
import { updateCredit } from "../../api/tarifs_api";
import { COLORS } from "../../constants/ColorsData";

type ResponseStateType = "success" | "failure";

const MailPreparation:React.FC<PageGenerationProps> = ({setPageNameToLoad, creditValue, creditUpdater}) => {
    const user_id = localStorage.getItem('userID')
    /**S'active lorsqu'on lance une requête. Et s'arrête en cas de réponse ou erreur */
    const [requestStatus, setRequestStatus] = useState(false);
    const [requestStatusResult, setRequestStatusResult] = useState<ResponseStateType>("success");
    const [isReadytoSend, setIsReadytoSend] = useState(false);
    const [showCheckBox, setShowCheckBox] = useState({
        showTitle: false,
        showAccroche: false,
        showMailFormat: false,
    })
    const [showFormatMail, setShowFormatMail] = useState(false);
    const [usingTech, setUsingTech] = useState(false);
    const [LoaderMessage, setLoaderMessage] = useState("");
    const [emptyField, setEmptyField] = useState({
        state1: false, // Titre du mail
        state2: false, // Accroche
        state3: false, // Checkbox
        state4: false // Incitation
    });
    const [CTA_index, setCTA_index] = useState("");
    const cta_ref: any = useRef(null)
    const [recipientData, setRecipientData] = useState<RecipientData>({
        activity_domain: [{title: "", status: false}],
        target_position: [{title: "", status: false}],
        number_of_employees: 0,
        localisation: "",
        company_nb_one: "",
        company_nb_two: "",
        technology: ""
    });
    
    const [currentFlag, setCurrentFlag] = useState<FlagLanguageType>({
        name: "Français",
        classname: "fr"
    })
    const [flagList4Menu, setFlagList4Menu] = useState<Array<FlagLanguageType>>(LANGUAGE_LIST)

    const [senderData, setSenderData] = useState<SenderData>({
        firstname: "",
        lastname: "",
        email_adress: "",
        job: "",
        companyName: "",
        companyActivity: "",
        serviceDesc: [],
        ref_companyActivity: "",
        ref_company_name: ""
    });

    const [mailTitleCheckboxData, setMailTitleCheckboxData] = useState<Array<CheckBoxList>>([
        {title: "Court", status: false, display: true},
        {title: "Personnalisé", status: false, display: true},
        {title: "Engageant", status: false, display: true}
    ])
    const [mailAccCheckboxData, setMailAccCheckboxData] = useState<Array<CheckBoxList>>([
        {title: "Innovant", status: false, display: true},
        {title: "Résiliant", status: false, display: true},
        {title: "Guidant", status: false, display: true}
    ])
    const [userFormData, setUserFormData] = useState<MailData2Send>({
        mailTitle: "",
        accroche: "",
        mailFormat: MAIL_TITLE_AND_STATE,
        incitation: ""
    });
    
    const goToNextPage = () => {
        Redirect2NewPage("Aurélia", `Dashboard/CampaignConfig`)
    }

    const updateMailTitle = (val: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setIsReadytoSend(false)
        const temp = val.target.value
        setUserFormData(prevS => ({ ...prevS, mailTitle: temp }))
    }

    const updateAccValue = (val: React.ChangeEvent<HTMLInputElement>) => {
        setIsReadytoSend(false)
        const temp = val.target.value
        setUserFormData(prevS => ({ ...prevS, accroche: temp }))
    }

    const verifyCheckboxChecked = (checkbox_state: boolean) => {
        setIsReadytoSend(false)
        //Si checkbox_state est true <=> une casé coché, donc champ non vide. Sinon false
        setEmptyField(prevS => ({ ...prevS, state3: checkbox_state ? false : true })) 
    }

    // eslint-disable-next-line
    const debitCredit = (credit_count: number, credit_to_be_debited: number = 1) => {
        if(credit_count <= 0) {
            alert("Crédit insuffisant")
        }else {
            const ls_user_data = localStorage.getItem(USER_INFO_KEY)

            if(ls_user_data) {
                const ls_user_data_value = JSON.parse(ls_user_data)
                const data = {
                    email: ls_user_data_value.email,
                    credit_count: credit_count - credit_to_be_debited,
                    // subscription_type: "trial_version",
                    // trial_expiration: false
                }
                updateCredit(data).then((resp) => {
                    if(resp && resp.data && resp.data.body.status === "success") {
                        creditUpdater(data.credit_count)
                    }
                }).catch((e) => console.error(e))
            }
        }
    }

    const focusCombobox = () => {
        if(cta_ref !== null && cta_ref.current !== null) cta_ref.current.focus();
    };

    const updateData = () => {
        localStorage.setItem(INCITATION_KEY, CTA_index)
        localStorage.setItem(ACCROCHE_KEY, userFormData.accroche)
        localStorage.setItem(MAIL_LANGUAGE_KEY, JSON.stringify(currentFlag))
        // localStorage.setItem(TITRE_MAIL_KEY, userFormData.mailTitle)
        const ls_mail_title_adj = localStorage.getItem(MAIL_TITLE_CHECKBOX_KEY)
        setUserFormData(prev => ({...prev, mailTitle: ls_mail_title_adj ? retrieveFinalMailTitle(JSON.parse(ls_mail_title_adj)) : userFormData.mailTitle}))
        
        // const ls_mail_acc_adj = localStorage.getItem(MAIL_ACC_CHECKBOX_KEY)
        // setUserFormData(prev => ({...prev, accroche: ls_mail_acc_adj ? retrieveFinalMailTitle(JSON.parse(ls_mail_acc_adj)) : userFormData.accroche}))
        
        // if (!userFormData.mailTitle){
        //     setEmptyField(prevState => ({...prevState, state1: true}))
        //     return
        // }else setEmptyField(prevState => ({...prevState, state1: false}))

        // if (!userFormData.accroche){
        //     setEmptyField(prevState => ({...prevState, state2: true}))
        //     return
        // }else setEmptyField(prevState => ({...prevState, state2: false}))

        if (emptyField.state3){
            setEmptyField(prevState => ({...prevState, state3: true}))
            return
        }else setEmptyField(prevState => ({...prevState, state3: false}))

        if (!userFormData.incitation){
            setEmptyField(prevState => ({...prevState, state4: true}))
            focusCombobox()
            return
        }else setEmptyField(prevState => ({...prevState, state4: false}))

        const ls_mail_format = localStorage.getItem(MAIL_CHECKBOX_KEY)

        if(ls_mail_format && ls_mail_format.length > 0) {
            const parsedData = JSON.parse(ls_mail_format) as CheckBoxList[];
                
                if (Array.isArray(parsedData)) {
                    setUserFormData(prevData => ({
                        ...prevData,
                        mailFormat: parsedData // Mettre à jour mailFormat après avoir mis à jour les autres champs
                    }));
                } else {
                    console.error('Les données du localStorage ne sont pas un tableau.');
                }
        } else setEmptyField(prev => ({...prev, state3: true}))

        if(userFormData.incitation && !emptyField.state3) {
            setIsReadytoSend(true)

            const mail_data = {
                "titleAdj": extractAllTitleOrFormatMail(ls_mail_title_adj),
                "accroche": [userFormData.accroche],
                "mailFormat": extractAllTitleOrFormatMail(ls_mail_format),
                "callToAction": userFormData.incitation
            }

            encryptData(V2_MAIL_KEY, mail_data)
        }
    }

    const updateFlagLanguage = (items: FlagLanguageType) => {
        // Mettre la valeur actuelle en sauvegarde temporaire
        const temp_items: FlagLanguageType = currentFlag

        setFlagList4Menu(prevFlagList => {
            return prevFlagList.map(flag => {
                if (flag.name === items.name) {
                    return temp_items
                } else {
                    return flag;
                }
            });
        });
        // Mettre à jour la langue courante avec celle choisie
        setCurrentFlag(items)

        localStorage.setItem(MAIL_LANGUAGE_KEY, JSON.stringify(items))
    }

    const language_and_flag_view = () => {
        let array_view: Array<JSX.Element> = []

        flagList4Menu.forEach((item, index) => {
            array_view.push(
                <li key={index}>
                    <p onClick={() => updateFlagLanguage(item)} className={item.classname}>{item.name}</p>
                </li>
            )
        })
        
        return (
            <ul>{array_view}</ul>
        )
    }

    const sendFormaData2Aurelia = () => {
        if(localStorage.getItem(DEST_SAVING_STATE) === null){
            Redirect2NewPage("Aurélia", "Dashboard/Destinataire")
        }else if(localStorage.getItem(SENDER_SAVING_STATE) === null){
            Redirect2NewPage("Aurélia", "Dashboard/Emetteur")
        }else{
            const final_data = {
                _id: localStorage.getItem(DOC_ID_KEY),
                user_id: user_id,
                recipient: recipientData,
                sender: senderData,
                mail : userFormData,
                language: currentFlag
            }

            var crm_api_data = localStorage.getItem(CRM_API_KEY)
            const sep = "||"
            const dolar_sep = "$$"

            if(user_id){
                setRequestStatus(true)
                setRequestStatusResult("success")
                setLoaderMessage("Envoi en cours...")

                let api_key_list_to_prompt = ""

                if(crm_api_data !== null){
                    const crm_api_data_value = JSON.parse(crm_api_data)

                    const keysArray: string[] = [];

                    for (const key in crm_api_data_value) {
                        if (crm_api_data_value.hasOwnProperty(key) && crm_api_data_value[key].hasOwnProperty('key')) {
                            keysArray.push(crm_api_data_value[key].key);
                        }
                    }
                    
                    const ordre_reel_tableau = ["Hubspots", "Apollo", "Salesforce", "Google Sheets"];
                    const tableau: string[] = ["Hubspots", "Google Sheets",  "Salesforce","Apollo"];

                    const tableau_value_ordonne: string[] = ordonnerTableau(tableau, ordre_reel_tableau, keysArray);

                    const decrypt_value_sf = decryptData(SF_CREDS_DATA)
                    
                    if(decrypt_value_sf !== ""){
                        let temp_arr = [decrypt_value_sf].join("§§")
                        tableau_value_ordonne[ordre_reel_tableau.indexOf("Salesforce")] = temp_arr
                    }else {
                        tableau_value_ordonne[ordre_reel_tableau.indexOf("Salesforce")] = "§§§§"
                    }

                    // Separated Key
                    api_key_list_to_prompt = tableau_value_ordonne.join(dolar_sep)
                }

                const temp_cta_idx = String(INCITATION_DATA[0]['content'].indexOf(CTA_index))

                // Concatenation
                const target_domain_concat = getOnlyTrueStatus(recipientData.activity_domain)
                const target_job_concat = getOnlyTrueStatus(recipientData.target_position)

                var mail_concat = convertFormatData2Text(userFormData.mailFormat)

                const ls_mail_format = localStorage.getItem(MAIL_CHECKBOX_KEY)
                if(ls_mail_format) {
                    const mail_format_value = JSON.parse(ls_mail_format)
                    mail_concat = convertFormatData2Text(mail_format_value)
                }
                                
                let prompt_base = "worker_search" + sep + target_job_concat + sep + target_domain_concat + sep + senderData.email_adress + "|€€€|" + api_key_list_to_prompt + sep + mail_concat + sep + userFormData.accroche + sep + temp_cta_idx + sep + senderData.companyActivity + sep + senderData.serviceDesc[0] + sep + " " + senderData.firstname + " " + senderData.lastname + sep + senderData.job + sep + userFormData.mailTitle + sep + senderData.companyName + sep + senderData.ref_company_name + sep + senderData.ref_companyActivity + sep + "mail_number_count" + sep + recipientData.number_of_employees + dolar_sep + recipientData.localisation + sep + recipientData.technology
                
                encryptData(PROMPT2LLM_KEY, prompt_base)
                setLoaderMessage("Enregistrement des informations...")
                
                // Mettre à jour le format de mail aurélia
                updateUserMailForm4Aurelia(final_data).then((resp) => {
                    setRequestStatus(false)
                    
                    if(resp.body.status === "success") {
                        localStorage.removeItem(RECIPIENT_KEY)
                        localStorage.removeItem(SENDER_KEY)
                        localStorage.removeItem(MAIL_CHECKBOX_KEY)
                        localStorage.removeItem(ACCROCHE_KEY)
                        localStorage.removeItem(TITRE_MAIL_KEY)
                        localStorage.removeItem(INCITATION_KEY)
                        localStorage.removeItem(DOC_ID_KEY)
                        localStorage.removeItem(DOMAINE_CHECKBOX_KEY)
                        localStorage.removeItem(JOB_CHECKBOX_KEY)
                        localStorage.removeItem(TECHNOLOGY_KEY)
                        localStorage.removeItem(MAIL_TITLE_CHECKBOX_KEY)

                        localStorage.removeItem(DEST_SAVING_STATE)
                        localStorage.removeItem(SENDER_SAVING_STATE)

                        goToNextPage() 
                    }
                    else {
                        setLoaderMessage("Erreur de mise à jour de données de mail")
                    }
                }).catch((e) => {
                    setLoaderMessage("Erreur d'accès au serveur")
                    setRequestStatus(false)
                    setRequestStatusResult("failure")
                    console.error(e)
                })
                    
                setIsReadytoSend(false)
            }
        }
    }

    const sendFormaData2AureliaV2 = () => {
        if(localStorage.getItem(DEST_SAVING_STATE) === null){
            Redirect2NewPage("Aurélia", "Dashboard/Destinataire")
        }else if(localStorage.getItem(SENDER_SAVING_STATE) === null){
            Redirect2NewPage("Aurélia", "Dashboard/Emetteur")
        }else{
            var crm_api_data = localStorage.getItem(CRM_API_KEY)
            const sep = "||"
            const dolar_sep = "$$"

            if(user_id){
                setRequestStatus(true)
                setRequestStatusResult("success")
                setLoaderMessage("Envoi en cours...")

                let api_key_list_to_prompt = ""

                if(crm_api_data !== null){
                    const crm_api_data_value = JSON.parse(crm_api_data)

                    const keysArray: string[] = [];

                    for (const key in crm_api_data_value) {
                        if (crm_api_data_value.hasOwnProperty(key) && crm_api_data_value[key].hasOwnProperty('key')) {
                            keysArray.push(crm_api_data_value[key].key);
                        }
                    }
                    
                    const ordre_reel_tableau = ["Hubspots", "Apollo", "Salesforce", "Google Sheets"];
                    const tableau: string[] = ["Hubspots", "Google Sheets",  "Salesforce","Apollo"];

                    const tableau_value_ordonne: string[] = ordonnerTableau(tableau, ordre_reel_tableau, keysArray);

                    const decrypt_value_sf = decryptData(SF_CREDS_DATA)
                    
                    if(decrypt_value_sf !== ""){
                        let temp_arr = [decrypt_value_sf].join("§§")
                        tableau_value_ordonne[ordre_reel_tableau.indexOf("Salesforce")] = temp_arr
                    }else {
                        tableau_value_ordonne[ordre_reel_tableau.indexOf("Salesforce")] = "§§§§"
                    }

                    // Separated Key
                    api_key_list_to_prompt = tableau_value_ordonne.join(dolar_sep)
                }

                // Récupération des données Déstinataire, émetteur, mail
                const final_data = {
                    _id: localStorage.getItem(DOC_ID_KEY),
                    language: currentFlag,
                    user_id: user_id,
                    recipient: decryptData(V2_RECIPIENT_KEY),
                    sender: decryptData(V2_SENDER_KEY),
                    mail : decryptData(V2_MAIL_KEY),
                    campaign : {
                        campaign_number: 1
                    }
                }               

                encryptData(PROMPT2LLM_KEY, JSON.stringify(final_data))
                setLoaderMessage("Enregistrement des informations...")
                
                // Mettre à jour le format de mail aurélia
                updateUserMailForm4Aurelia(final_data).then((resp) => {
                    setRequestStatus(false)
                    
                    if(resp.body.status === "success") {
                        // localStorage.removeItem(RECIPIENT_KEY)
                        // localStorage.removeItem(SENDER_KEY)
                        // localStorage.removeItem(MAIL_CHECKBOX_KEY)
                        // localStorage.removeItem(ACCROCHE_KEY)
                        // localStorage.removeItem(TITRE_MAIL_KEY)
                        // localStorage.removeItem(INCITATION_KEY)
                        // localStorage.removeItem(DOC_ID_KEY)
                        // localStorage.removeItem(DOMAINE_CHECKBOX_KEY)
                        // localStorage.removeItem(JOB_CHECKBOX_KEY)
                        // localStorage.removeItem(TECHNOLOGY_KEY)
                        // localStorage.removeItem(MAIL_TITLE_CHECKBOX_KEY)

                        // localStorage.removeItem(DEST_SAVING_STATE)
                        // localStorage.removeItem(SENDER_SAVING_STATE)

                        goToNextPage() 
                    }
                    else {
                        setLoaderMessage("Erreur de mise à jour de données de mail")
                        setRequestStatus(false)
                    }
                }).catch((e) => {
                    setLoaderMessage("Erreur d'accès au serveur")
                    setRequestStatus(false)
                    setRequestStatusResult("failure")
                    console.error(e)
                })
                    
                setIsReadytoSend(false)
            }
        }
    }

    /**
     * Converti le tableau en un texte séparé par des virgules (adjectifs)
     * @param data 
     * @returns 
     */
    const retrieveFinalMailTitle = (data: Array<CheckBoxList>) => {
        let title_mail_adj: string[] = []
        data.forEach((item, index) => {
            if(item.status) {
                title_mail_adj.push(item.title)
            }
        })

        return title_mail_adj.join(",")
    }

    const formatMailDataFilter = useCallback(() => {
        let data: Array<CheckBoxList> = userFormData.mailFormat

        userFormData.mailFormat.forEach((items, index) => {
            data[index].status = false

            switch(currentFlag.name) {
                case "Anglais":
                    if(items.title === "Healthtech") {
                        data[index].display = true
                    } else data[index].display = false
                    break
                case "Portugais":
                    if(items.title === "Format contextuel") {
                        data[index].display = true
                    } else data[index].display = false
                    break
                default:
                    if(usingTech) {
                        if(items.title !== "Technologies") {
                            data[index].display = false
                        } else data[index].display = true
                    } else {
                        if(items.title === "Healthtech" || items.title === "Technologies") {
                            data[index].display = false
                        } else data[index].display = true
                    }
                    break
            }
        })

        return data
    }, [currentFlag, userFormData.mailFormat, usingTech])

    const extractAllTitleOrFormatMail = (data: any) => {
        if(data) {
            let finalJsonArray = []
            const jsonArray_data = JSON.parse(data)

            for (const iterator of jsonArray_data) {
                if(iterator['status']) {
                    finalJsonArray.push(iterator['title'])
                }
            }
            return finalJsonArray
        }else {
            return []
        }
    }

    /**
     * USE EFFECT
     */
    // Initiation
    useEffect(() => {
        // Récupérer la lange stocké en local {name: "Français", classname: "fr"}
        const ls_lang = localStorage.getItem(MAIL_LANGUAGE_KEY)
        const default_language = {
            name: "Français",
            classname: "fr"
        }

        if(ls_lang){
            try{
                let temp_flagList: Array<FlagLanguageType> = flagList4Menu

                const lang: FlagLanguageType = JSON.parse(ls_lang)
                setCurrentFlag(lang)

                flagList4Menu.forEach((lang_item, index) => {
                    // Langue courante figure déjà dans la liste des options
                    if(lang.name === lang_item.name) {
                        temp_flagList[index] = default_language // remplacer par le français
                    }
                })

                // Mettre à jour la liste
                setFlagList4Menu(temp_flagList)
            }catch(e) {
                localStorage.removeItem(MAIL_LANGUAGE_KEY)
            }
        }

        // Récupérer les formats de mail depuis la base de données
        getMailFormatListDataFromDB().then(
            (rep: any) => {
                if(rep && rep.body){
                    const body: Array<MailFormatCheckBoxType> = rep.body
                    setUserFormData(prev => ({...prev, mailFormat: body}))

                    if(body.length > 0) localStorage.setItem(MAIL_CHECKBOX_KEY, JSON.stringify(body))
                    // Format de mail
                    const ls_mail_format = localStorage.getItem(MAIL_CHECKBOX_KEY)
                    if(ls_mail_format) {
                        const mail_format_value = JSON.parse(ls_mail_format)
                        if(mail_format_value) {
                            setShowCheckBox(prevState => ({...prevState, showTitle: true,showMailFormat : true}))
                        }
                    }
                }
            }
        )
    }, [flagList4Menu])

    useEffect(() => {
        setIsReadytoSend(false)
        setUserFormData(prev => ({...prev, incitation: CTA_index}))
    }, [CTA_index])

    useEffect(() => {
        const data_mail_format = formatMailDataFilter()
        setUserFormData(prev => ({...prev, mailFormat: data_mail_format}))
    }, [currentFlag, formatMailDataFilter])

    /**
     * Chargement des informations stockées en local et maj des champs
     */
    useEffect(() => {     
        setShowFormatMail(false)

        let localS_recipient = localStorage.getItem(RECIPIENT_KEY)
        let localS_sender = localStorage.getItem(SENDER_KEY)
        let localS_accroche = localStorage.getItem(ACCROCHE_KEY)
        let localS_mailTitle = localStorage.getItem(TITRE_MAIL_KEY)
        let localS_mailformat = localStorage.getItem(MAIL_CHECKBOX_KEY)
        let localS_incitation = localStorage.getItem(INCITATION_KEY)
        const ls_mail_title = localStorage.getItem(MAIL_TITLE_CHECKBOX_KEY)
        const ls_technology = localStorage.getItem(TECHNOLOGY_KEY)

        if(ls_mail_title){
            const ls_mail_title_value = JSON.parse(ls_mail_title)
            setMailTitleCheckboxData(ls_mail_title_value)
            setUserFormData(prev => ({...prev, mailTitle: retrieveFinalMailTitle(ls_mail_title_value)}))
        }
        // const ls_mail_acc = localStorage.getItem(MAIL_ACC_CHECKBOX_KEY)
        // if(ls_mail_acc){
        //     const ls_mail_acc_value = JSON.parse(ls_mail_acc)
        //     setMailAccCheckboxData(ls_mail_acc_value)
        //     setUserFormData(prev => ({...prev, accroche: retrieveFinalMailTitle(ls_mail_acc_value)}))
        // }
    
        if (localS_recipient !== null && localS_recipient !== undefined) {
            setRecipientData(prevS => ({ ...prevS, user_id: String(user_id)}))
            setRecipientData(JSON.parse(String(localS_recipient)))
        } else setIsReadytoSend(false)

        if (localS_sender !== null && localS_sender !== undefined) {
            setSenderData(prevS => ({ ...prevS, user_id: String(user_id)}))
            setSenderData(JSON.parse(String(localS_sender)))
        } else setIsReadytoSend(false)

        if (localS_mailTitle !== null && localS_mailTitle !== undefined && localS_mailTitle !== "") {
            setUserFormData(prevS => ({ ...prevS, mailTitle: String(localS_mailTitle)}))
        } else setIsReadytoSend(false)

        if (localS_accroche !== null && localS_accroche !== undefined && localS_accroche !== "") {
            setUserFormData(prevS => ({ ...prevS, accroche: String(localS_accroche)}))
        } else setIsReadytoSend(false)

        if (localS_incitation !== null && localS_incitation !== undefined && localS_incitation !== "") {
            setUserFormData(prevS => ({ ...prevS, incitation: String(localS_incitation)}))
        } else setIsReadytoSend(false)
    
        if (localS_mailformat !== null && localS_mailformat !== undefined) {
            try {   
                const parsedData = JSON.parse(localS_mailformat) as CheckBoxList[];
                
                if (Array.isArray(parsedData)) {
                    setUserFormData(prevData => ({
                        ...prevData,
                        mailFormat: parsedData // Mettre à jour mailFormat après avoir mis à jour les autres champs
                    }));
                    setShowFormatMail(true)
                    // Retourne true si une des cases à cocher est coché
                    const hasTrueStatus = parsedData.some(item => item.status === true);

                    if(hasTrueStatus) setIsReadytoSend(true)
                } else {
                    console.error('Les données du localStorage ne sont pas un tableau.');
                    setShowFormatMail(true)
                }
                
            } catch (error) {
                console.error('Erreur lors de l\'analyse des données du localStorage:', error);
                setShowFormatMail(true)
            }
        } else {
            setShowFormatMail(true)
        }

        if(ls_technology) {
            setUsingTech(true)
        }
        
    }, [user_id, isReadytoSend])

    useEffect(() => {
        // Titre du mail
        const ls_mail_title = localStorage.getItem(MAIL_TITLE_CHECKBOX_KEY)
        if(ls_mail_title) {
            const mail_title_value = JSON.parse(ls_mail_title)
            if(mail_title_value) setShowCheckBox(prevState => ({...prevState, showTitle : true}))
        }
        
        // Accroche
        const ls_accroche = localStorage.getItem(MAIL_ACC_CHECKBOX_KEY)
        if(ls_accroche) {
            const accroche_value = JSON.parse(ls_accroche)
            if(accroche_value) setShowCheckBox(prevState => ({...prevState, showAccroche : true}))
        }
    }, [])

    return (
        <>
            <div id="mail-prep-creneau-container" className="scroll">
                <div className="row-1">
                    <div className="mail-prep-right-text">
                        <div style={{ display: "flex", flex: 1, flexDirection: 'column', padding: 5, width: 200}}>
                            <label>Langue (Optionnel)</label>
                            <div className="lang-menu">
                                <div className={currentFlag.classname + " selected-lang"}>
                                    {currentFlag.name}
                                </div>
                                {language_and_flag_view()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-1">
                    <div className={emptyField.state1 ? "container-error" : "container-no-error"}>
                        {
                            showCheckBox.showTitle ? (
                                <div className={emptyField.state3 ? "container-error" : "container-no-error"}>
                                    <Checkbox 
                                        title="Titre du mail (Optionnel)" 
                                        data={mailTitleCheckboxData} 
                                        store_name={MAIL_TITLE_CHECKBOX_KEY} 
                                        isAllChecked={() => {}} 
                                    />
                                </div>
                            ) : (
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <LoaderResponse colorValue={COLORS.jauneClaire} />
                                </div>
                            )
                        }
                    </div>
                    {/* <div className="mail-prep-right-text">
                        <div className={emptyField.state1 ? "error-field" : "no-error"}
                            style={{ display: "flex", flex: 1, flexDirection: 'column', padding: 5}}>
                            <label>Titre du mail (Optionnel)</label>
                            <input value={userFormData.mailTitle} onChange={(val) => updateMailTitle(val)} type="text" className={emptyField.state1 ? "error-field input_text" : "no-error input_text"} placeholder="Veuillez saisir trois adjectifs (Ex: Créatif, Prometteur, Efficace)" />
                            <textarea 
                                className={"textarea-as-input"}
                                value={userFormData.mailTitle}
                                onChange={(val) => updateMailTitle(val)}
                                placeholder="Secret de polichinelle : ( produit vendu) et (société cible)
                                Comment voyez-vous-le (produit /service) chez (société) ?"></textarea>
                        </div>
                    </div> */}
                </div>
                <div className={emptyField.state2 ? "error-field" : "no-error row-1"}>
                    {/* <div className={emptyField.state3 ? "container-error" : "container-no-error"}>
                        <Checkbox title="Accroche (Optionnel)" data={mailAccCheckboxData} store_name={MAIL_ACC_CHECKBOX_KEY} isAllChecked={() => {}} />
                    </div> */}
                    <div className="mail-prep-right-text">
                        <div style={{ display: "flex", flex: 1, flexDirection: 'column', padding: 5 }}>
                            <label>Accroche (Optionnel)</label>
                            <input type="text" value={userFormData.accroche} onChange={(val) => updateAccValue(val)} className={emptyField.state2 ? "error-field input_text" : "no-error input_text"} placeholder="Veuillez proposer trois adjectifs (Ex: Innovant, Résilient , Guidant)" />
                        </div>
                    </div>
                </div>
                <div className="row-1">
                    {
                        showCheckBox.showMailFormat ? (
                            <div className={emptyField.state3 ? "container-error" : "container-no-error"}>
                                <Checkbox 
                                    title="Format du mail" 
                                    data={userFormData.mailFormat} 
                                    store_name={MAIL_CHECKBOX_KEY} 
                                    isAllChecked={verifyCheckboxChecked} 
                                />
                            </div>
                        ) : (
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <LoaderResponse colorValue={COLORS.jauneClaire} />
                            </div>
                        )
                    }
                </div>
                
                <div ref={cta_ref} className={emptyField.state4 ? "container-error" : "container-no-error row-1"}>
                    <ComboBox data={userFormData.incitation} getOptionIndex={setCTA_index} />
                </div>
            </div>

            <div id="form-btn-container">
                {
                    requestStatus ? (
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                            <LoaderResponse colorValue={COLORS.jauneClaire} />
                            <p style={{color: requestStatusResult === "success" ? COLORS.noireMoinsSombre : 'red', paddingLeft: 10}}>{LoaderMessage}</p>
                        </div>
                    ) : (
                        <button id="form-btn" onClick={isReadytoSend ? sendFormaData2AureliaV2 : updateData}>{isReadytoSend ? "Passer à l'étape suivant" : "Enregistrer"}</button> 
                            // : <button id="form-btn" onClick={updateData}>Enregistrer</button>
                    )
                }
                
            </div>
        </>
    )
}

export default MailPreparation