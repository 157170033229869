import { USER_ID_KEY } from "../constants/StoreKey"

export const Redirect2NewPage = (llm_name: string, text?: string, id?: string) => {
  const userid = id === undefined ? String(localStorage.getItem(USER_ID_KEY)) : id
  const windowFeatures = "left=110,top=110,width=320,height=480";
  
  switch(llm_name){
    case "welcoming":
      window.location.replace("/welcoming")
      break

    case "login":
      window.location.replace(`/${llm_name}`)
      break

    case "accueil":
      window.location.replace("/")
      break

    case "logout":
      localStorage.removeItem('userID')
      window.location.replace("/login")
      break

    case "Aurélia":
      if(text === "/info"){
        window.open(
          "/info/"+ llm_name, 
          "MsgWindow", 
          windowFeatures
        );
        break
      }
      else if(text !== undefined && text.includes("Dashboard")){
        const paths = `/Aurelia/${text}/${userid}`
        if(text === "Dashboard/CampaignHistory" || text === "Dashboard/CampaignConfig") {
          window.location.href = paths
        } 
        else window.location.replace(paths)
        break
      }
      else if (text !== undefined) {
        window.open(text)
        break
      } 
      else {
        window.open(`/llm/?model=${llm_name}`);
        window.setTimeout(() => {
          window.close();
        }, 1000);
        break
      }

    default:
      window.location.replace("/")
  }
}